import { getGlobalThis } from './global-this';

export async function loadScriptWithGlobal<TScriptGlobal>(url: string, globalName: string, targetElement: Element = null): Promise<TScriptGlobal> {
	// Get the global (i.e. window) object, where scripts' global namespaces should be found when loaded
	const globals = getGlobalThis() as any;

	// Return the existing global API reference if it exists
	if (globals[globalName]) {
		// console.log("Global '" + globalName + "' is already loaded.");
		return globals[globalName] as TScriptGlobal;
	}

	if (!targetElement) {
		if (globals.document) {
			targetElement = globals.document.head;
		}
		else {
			throw new Error('Can\'t load script without a target element.');
		}
	}

	// Load the target library via a `<script>` tag, and return the object when it loads
	return new Promise<TScriptGlobal>((resolve, reject) => {
		// console.log("Injecting <script> to load '" + globalName + "'...");
		const script = document.createElement('script');
		script.setAttribute('src', url);
		script.onload = () => {
			if (globals[globalName]) {
				resolve(globals[globalName] as TScriptGlobal);
			}
			else {
				reject(new Error(`Script '${url}' failed to load.`));
			}
		};

		targetElement.appendChild(script);
	});
}
